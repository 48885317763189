import React from 'react'
import Helmet from "react-helmet";
import styled from '@emotion/styled';

import Layout from '../components/Layout'
import Header from "../components/Header";
import Container from "../components/Container";
import Contact from "../components/Contact"

import backgroundPortable from "../img/bg-header-small.jpg"
import backgroundNotebook from "../img/bg-header-notebook.jpg"
import backgroundFullHD from "../img/bg-header-fullhd.jpg"

const HeaderWrapper = styled("div")`
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: url(${backgroundPortable});
  background-size: cover;
  background-position: center center;
  @media screen and (min-width: 1024px) {
    background-image: url(${backgroundNotebook});
  }
  @media screen and (min-width: 1440px) {
    background-image: url(${backgroundFullHD});
  }
  @media screen and (max-width: 550px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 550px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const NotFoundWrapper = styled("div")`
  padding-top: 6rem;
  padding-bottom: 6rem;
  img {
    display: block;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
`

export default () => {
  return (
    <Layout>
      <Helmet titleTemplate="%s | Den daňové svobody">
        <title>Kontakt</title>
        <meta
          name="description"
          content="Kontakt"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Kontakt" />
        <meta property="og:description" content="Kontakt" />
        <meta name="twitter:title" content="Kontakt" />
        <meta name="twitter:description" content="Kontakt" />
      </Helmet>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <Container>
        <NotFoundWrapper>
          <h1>Kontakt</h1>
          <Contact />
        </NotFoundWrapper>
      </Container>
    </Layout>
  );
};
